const debug = process.env.GATSBY_ENVIRONMENT !== 'production';

export function trackEvent(type, action, name, value) {
  if (debug) {
    console.log({
      event: 'GenericEvent',
      type: type,
      action: action,
      name: name,
      value: value,
    });
    return;
  }

  if (window.dnbDataLayer) {
    try {
      window.dnbDataLayer.push({
        event: 'GenericEvent',
        event_type: type,
        event_action: action,
        event_name: name,
        event_value: value,
      });
    } catch (error) {
      console.error(error);
    }
    return;
  }
}

export function pageLoad() {
  if (debug) {
    console.log('Dev - pageload', window.location);
    return;
  }

  if (window.dnbDataLayer) {
    try {
      window.dnbDataLayer.push({
        event: 'PageLoaded',
        page: {},
      });
    } catch (error) {
      console.error(error);
    }
  }
}
