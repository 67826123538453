import React from 'react';
import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import Quiz from '../components/quiz/Quiz';
import DebugPanel from '../components/debug-panel/DebugPanel';

const QuizPage = () => {
  const dev = process.env.GATSBY_ENVIRONMENT !== 'production';
  return (
    <Layout>
      {dev && <DebugPanel />}
      <Seo />
      <Quiz />
    </Layout>
  );
};

export default QuizPage;
