import React from 'react';
import PropTypes from 'prop-types';
import '@dnb/eufemia/style';

import * as styles from './QuizHeader.module.scss';

const QuizHeader = ({ currentQuestion, totalQuestion }) => {
  return (
    <header className={styles.header}>
      {currentQuestion + 1 > totalQuestion
        ? totalQuestion
        : currentQuestion + 1}{' '}
      av {totalQuestion}
    </header>
  );
};

QuizHeader.propTypes = {
  currentQuestion: PropTypes.number.isRequired,
  totalQuestion: PropTypes.number.isRequired,
};

QuizHeader.defaultProps = {
  currentQuestion: 0,
  totalQuestion: 0,
};

export default QuizHeader;
