import React from 'react';
import classNames from 'classnames';
import Animate from '../animate/Animate';
import * as styles from './AnimationSwitch.module.scss';
import road from '../../animations/road/road.json';
import car from '../../animations/car/car_30.json';
import carA from '../../animations/car/car_30_A.json';
import carB from '../../animations/car/car_30_B.json';
import carLights from '../../animations/car/car_30_lights.json';
import measure from '../../animations/measure/measure.json';
import buss from '../../animations/buss/buss.json';
import roundaboutImage from '../../images/rundkjoring-s.png';
import crossingImage from '../../images/crossing-s.png';

const AnimationSwitch = ({ type }) => {
  const roadStyle = {
    position: 'absolute',
    bottom: '0',
    right: '-520px',
  };

  const carStyle = {
    position: 'absolute',
    bottom: '208px',
    right: '455px',
  };

  const Road = ({ autoplay }) => {
    return (
      <Animate
        autoplay={autoplay}
        style={roadStyle}
        width={1664}
        height={826}
        animation={road}
      />
    );
  };

  const Car = ({ carType = car, autoplay, style = carStyle }) => {
    return (
      <Animate
        autoplay={autoplay}
        style={style}
        width={124}
        height={88}
        animation={carType}
      />
    );
  };

  const DefaultCarAndRoad = ({ autoplay }) => {
    return (
      <>
        <Road autoplay={autoplay} />
        <Car autoplay={autoplay} />
      </>
    );
  };

  switch (type) {
    case 'none':
      return (
        <div className={styles.roadSignIllustration}>
          <DefaultCarAndRoad autoplay={true} />
        </div>
      );

    case 'roundabout':
      const roundaboutCar = {
        position: 'absolute',
        bottom: '28px',
        right: '525px',
      };

      return (
        <div className={classNames(styles.roundaboutOuter, styles.corner)}>
          <img
            className={styles.corner}
            src={roundaboutImage}
            alt="roundabout"
          />
          <Animate
            autoplay={false}
            style={roundaboutCar}
            width={85}
            height={65}
            animation={car}
          />
          <div>
            <svg
              className={styles.roundPopUp}
              id="one"
              width="51"
              height="80"
              viewBox="0 0 51 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M50.0737 16.7308C49.7062 9.76624 46.6286 5.16314 41.9892 3.61166C40.1862 2.99563 32.406 1.33006 30.3964 0.99923C27.0374 0.480169 23.2937 1.13614 19.4869 3.11542C7.08448 9.53809 -1.5168 26.9751 0.406722 41.3948C0.782761 44.8238 1.96269 48.1175 3.85183 51.0116L3.88053 51.0459C4.26509 51.6061 4.68509 52.1414 5.138 52.6487L25.9866 79.1265C26.2223 79.4395 26.5586 79.6629 26.9398 79.7597C27.2168 79.826 27.5045 79.8349 27.7851 79.7859C28.0656 79.7368 28.333 79.6308 28.5705 79.4745L28.6681 79.4174C29.767 78.7054 30.5954 77.6496 31.0223 76.4172L47.0994 32.5593C47.4554 31.6752 47.777 30.7911 48.0755 29.9013V29.8499C49.5465 25.6358 50.2239 21.1886 50.0737 16.7308Z"
                  fill="#007272"
                />
                <path
                  d="M48.082 29.8218C49.553 25.6076 50.2303 21.1605 50.0801 16.7027C49.437 4.54749 40.52 -0.409261 29.5932 5.29471C17.277 11.7345 7.11963 28.8293 7.11963 42.8211C7.11963 46.5629 7.84886 49.6659 9.14652 52.1243V52.1585C9.4354 52.6938 9.76154 53.2084 10.1226 53.6986L26.1998 79.1212C27.2276 80.7411 29.9837 79.1554 31.0114 76.3605L47.0886 32.5027C47.4446 31.6185 47.7662 30.7344 48.0647 29.8446L48.082 29.8218Z"
                  fill="#28B482"
                />
                <path
                  d="M41.5207 38.5728C48.6495 26.307 48.6489 13.0488 41.5196 8.9598C34.3902 4.87082 22.8318 11.4995 15.7031 23.7653C8.57436 36.0312 8.5749 49.2893 15.7042 53.3783C22.8336 57.4673 34.392 50.8387 41.5207 38.5728Z"
                  fill="#FBF6EC"
                />
                <path
                  d="M34.4108 33.478V37.4024L22.8065 44.0418V40.1117L26.4296 38.0526V25.2187C25.3746 26.4696 24.1538 27.5729 22.8008 28.4985V24.5399C24.7186 23.2394 27.0957 20.2448 28.1694 17.6951L30.6614 16.2634V35.6569L34.4108 33.478Z"
                  fill="#14555A"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="50"
                    height="79"
                    fill="white"
                    transform="translate(0.108887 0.799988)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              className={styles.roundPopUp}
              id="two"
              width="51"
              height="79"
              viewBox="0 0 51 79"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M50.904 15.9312C50.5365 8.96663 47.4589 4.36352 42.8195 2.81204C41.0165 2.19602 33.2421 0.530447 31.2267 0.199616C27.8735 -0.319445 24.124 0.336524 20.3172 2.3158C7.89184 8.70995 -0.720945 26.1299 1.21406 40.5666C1.5901 43.9957 2.77003 47.2894 4.65916 50.1835V50.2177C5.04567 50.778 5.4676 51.3134 5.92238 51.8206L26.8399 78.2984C27.0789 78.6105 27.4167 78.8335 27.7988 78.9315C28.0757 78.9979 28.3635 79.0068 28.6441 78.9577C28.9246 78.9087 29.192 78.8027 29.4295 78.6463L29.5271 78.5893C30.6241 77.8766 31.4505 76.8208 31.8755 75.589L47.9527 31.7312C48.303 30.8471 48.6302 29.9629 48.9231 29.0731V29.0218C50.385 24.8156 51.0564 20.3784 50.904 15.9312Z"
                  fill="#007272"
                />
                <path
                  d="M48.9173 29.0218C50.3866 24.8074 51.062 20.3601 50.9097 15.9027C50.2724 3.7475 41.3553 -1.20925 30.4228 4.49472C18.1066 10.9345 7.94922 28.0293 7.94922 42.0211C7.94922 45.7629 8.6842 48.8659 9.98186 51.3243V51.3585C10.2688 51.8937 10.593 52.4083 10.9522 52.8986L27.0294 78.3212C28.0514 79.9411 30.8133 78.3554 31.8353 75.5605L47.9125 31.7027C48.2627 30.8186 48.59 29.9344 48.8829 29.0446L48.9173 29.0218Z"
                  fill="#28B482"
                />
                <path
                  d="M42.3572 37.7715C49.4859 25.5056 49.4854 12.2474 42.356 8.15843C35.2267 4.06945 23.6682 10.6981 16.5395 22.964C9.41079 35.2298 9.41133 48.488 16.5407 52.577C23.67 56.6659 35.2285 50.0373 42.3572 37.7715Z"
                  fill="#FBF6EC"
                />
                <path
                  d="M28.8325 36.3514L36.1132 32.1761V36.1061L22.7749 43.7552V40.7264L27.2708 32.661C30.1417 27.6015 32.0824 24.6411 32.0824 21.9774C32.0824 19.9468 31.0087 19.365 29.4469 20.2662C27.9942 21.099 26.8631 22.776 26.4267 25.0575L22.8323 25.6279C23.182 23.6937 23.9558 21.8596 25.0989 20.2558C26.2421 18.6519 27.7265 17.3176 29.4469 16.3476C33.4203 14.066 36.1477 15.4293 36.1477 19.6559C36.1477 23.7514 32.7485 29.3527 30.1991 33.9158L28.8325 36.3514Z"
                  fill="#14555A"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="50"
                    height="79"
                    fill="white"
                    transform="translate(0.944336)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              className={styles.roundPopUp}
              id="three"
              width="51"
              height="80"
              viewBox="0 0 51 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M50.0272 16.1305C49.6597 9.1659 46.5821 4.5628 41.9426 3.01132C40.1397 2.39529 32.3652 0.729719 30.3499 0.398889C26.9966 -0.120173 23.2472 0.535797 19.4403 2.51507C7.03796 8.93775 -1.56906 26.3748 0.360203 40.7944C0.736242 44.2235 1.91617 47.5172 3.80531 50.4113V50.4456C4.19182 51.0058 4.61371 51.5412 5.06849 52.0484L25.9114 78.5262C26.147 78.8392 26.4834 79.0626 26.8646 79.1593C27.1415 79.2257 27.4292 79.2346 27.7098 79.1855C27.9904 79.1364 28.2578 79.0304 28.4953 78.8741C28.5296 78.8584 28.5623 78.8393 28.5929 78.8171C29.6917 78.105 30.5201 77.0492 30.947 75.8168L47.0242 31.959C47.3744 31.0749 47.7017 30.1908 47.9945 29.3009V29.2496C49.4747 25.037 50.1637 20.5902 50.0272 16.1305Z"
                  fill="#007272"
                />
                <path
                  d="M48.0413 29.2218C49.5078 25.0067 50.1831 20.56 50.0337 16.1027C49.3906 3.94751 40.4735 -1.00924 29.5468 4.69473C17.2305 11.1345 7.07324 28.2293 7.07324 42.2211C7.07324 45.963 7.80244 49.0659 9.10584 51.5243V51.5585C9.39278 52.0937 9.71703 52.6084 10.0762 53.0986L26.1534 78.5212C27.1754 80.1412 29.9373 78.5554 30.9593 75.7605L47.0365 31.9027C47.3867 31.0186 47.714 30.1344 48.0069 29.2446L48.0413 29.2218Z"
                  fill="#28B482"
                />
                <path
                  d="M41.4793 37.975C48.608 25.7092 48.6074 12.451 41.4781 8.36202C34.3487 4.27304 22.7903 10.9017 15.6616 23.1675C8.53287 35.4333 8.5334 48.6916 15.6627 52.7805C22.7921 56.8695 34.3506 50.2409 41.4793 37.975Z"
                  fill="#FBF6EC"
                />
                <path
                  d="M35.9665 28.8682C35.9665 32.9579 33.0038 37.8861 28.5653 40.4301C24.5058 42.7573 22.1287 41.896 21.2847 38.9813L24.9996 35.2224C25.436 37.1675 26.7681 37.504 28.5653 36.5058C30.1616 35.5875 31.7291 33.3572 31.7291 31.2981C31.7291 29.3359 30.3338 28.6001 28.4218 29.7009L26.7394 30.6649V26.9003L28.4218 25.9363C29.1376 25.487 29.7279 24.8659 30.1384 24.1302C30.5488 23.3945 30.7661 22.5679 30.7702 21.7268C30.7702 20.0156 29.5529 19.8274 28.3356 20.529C27.5517 20.9971 26.8823 21.6325 26.3759 22.3891C25.8695 23.1457 25.5386 24.0047 25.4073 24.9039L21.9622 25.7652C22.2433 23.8688 22.9636 22.0631 24.0666 20.4903C25.1696 18.9175 26.6251 17.6206 28.3184 16.7016C31.9415 14.6197 34.9043 14.8535 34.9043 18.9832C34.8786 20.1715 34.6168 21.3431 34.134 22.4306C33.6512 23.5181 32.9568 24.5001 32.0908 25.3203C34.5713 24.5446 35.9665 25.6055 35.9665 28.8682Z"
                  fill="#14555A"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="50"
                    height="79"
                    fill="white"
                    transform="translate(0.0683594 0.200012)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      );

    case 'no-entry-sign':
      return (
        <div className={styles.roadSignIllustration}>
          <DefaultCarAndRoad autoplay={false} />
          <svg
            className={styles.signPost}
            width="35"
            height="112"
            viewBox="0 0 35 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M19.678 2.2235C20.7159 2.2235 21.5573 1.73179 21.5573 1.12524C21.5573 0.518686 20.7159 0.0269775 19.678 0.0269775C18.6402 0.0269775 17.7988 0.518686 17.7988 1.12524C17.7988 1.73179 18.6402 2.2235 19.678 2.2235Z"
                fill="#28B482"
              />
              <path
                d="M17.7988 109.93C17.7988 110.536 18.6405 111.025 19.678 111.025C20.7156 111.025 21.5573 110.533 21.5573 109.93V1.12524C21.5573 1.73186 20.7156 2.22022 19.678 2.22022C18.6405 2.22022 17.7988 1.72858 17.7988 1.12524V109.93Z"
                fill="#007272"
              />
              <path
                d="M33.3935 33.5749C33.3935 38.8308 31.5694 42.5296 28.6203 44.2531C25.6711 45.9766 21.5967 45.728 17.0962 43.0979C12.5956 40.4678 8.52231 35.9554 5.57205 30.7849C2.62178 25.6144 0.798828 19.7869 0.798828 14.5278C0.798828 9.26859 2.62286 5.57194 5.57205 3.84955C8.52123 2.12715 12.5956 2.37461 17.0962 5.00475C21.5967 7.63488 25.6711 12.1484 28.6203 17.3167C31.5694 22.485 33.3935 28.3146 33.3935 33.5749Z"
                fill="#DC2A2A"
              />
              <path
                d="M6.97762 3.02832C9.92897 1.30811 14.0011 1.55338 18.5017 4.18352C23.0023 6.81365 27.0766 11.3261 30.0258 16.4954C32.975 21.6648 34.799 27.4934 34.799 32.7537C34.799 38.0139 32.9728 41.7128 30.0258 43.4319L28.6194 44.2531C31.5686 42.5296 33.3926 38.8341 33.3926 33.5749C33.3926 28.3157 31.5664 22.4872 28.6205 17.3167C25.6745 12.1462 21.597 7.63379 17.0964 5.00475C12.5958 2.37571 8.52253 2.12606 5.57227 3.84955L6.97762 3.02832Z"
                fill="#CCCCCC"
              />
              <path
                d="M30.9561 29.1358L3.23633 12.9379V18.9668L30.9561 35.1648V29.1358Z"
                fill="#F2F2F5"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="34"
                  height="111"
                  fill="white"
                  transform="translate(0.798828 0.0269775)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      );

    case 'buss':
      const bussStyle = {
        position: 'absolute',
        right: '293px',
        bottom: '250px',
      };

      const bussCarStyle = {
        position: 'absolute',
        right: '577px',
        bottom: '140px',
      };

      return (
        <div className={classNames(styles.bussOuter, styles.corner)}>
          <Road />
          <Car autoplay style={bussCarStyle} />
          <Animate
            autoplay={true}
            style={bussStyle}
            width={236}
            height={190}
            animation={buss}
          />
        </div>
      );

    case 'roundabout-sign':
      return (
        <div className={styles.roadSignIllustration}>
          <DefaultCarAndRoad />
          <svg
            className={styles.signPost}
            width="34"
            height="111"
            viewBox="0 0 34 111"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M16.9997 2.19652C18.0243 2.19652 18.8549 1.70482 18.8549 1.09826C18.8549 0.491709 18.0243 0 16.9997 0C15.9751 0 15.1445 0.491709 15.1445 1.09826C15.1445 1.70482 15.9751 2.19652 16.9997 2.19652Z"
                fill="#28B482"
              />
              <path
                d="M18.8549 109.902C18.8549 110.508 18.025 110.997 16.9997 110.997C15.9744 110.997 15.1445 110.505 15.1445 109.902V1.09827C15.1445 1.70488 15.9755 2.19324 16.9997 2.19324C18.024 2.19324 18.8549 1.7016 18.8549 1.09827V109.902Z"
                fill="#007272"
              />
              <path
                d="M4.8441 4.78067L5.76902 4.23318C12.2275 0.410616 22.6985 6.60819 29.1602 18.077C35.6218 29.5458 35.6186 41.9409 29.1602 45.7646L28.2353 46.312C21.7768 50.1346 11.3057 43.9359 4.84517 32.4683C-1.61541 21.0006 -1.61434 8.60323 4.8441 4.78067Z"
                fill="#CCCCCC"
              />
              <path
                d="M28.2325 46.3107C34.691 42.4879 34.6911 30.0918 28.2326 18.6234C21.7742 7.15502 11.303 0.957071 4.84453 4.77993C-1.61396 8.60279 -1.61404 20.9988 4.84437 32.4672C11.3028 43.9356 21.774 50.1336 28.2325 46.3107Z"
                fill="#F2F2F5"
              />
              <path
                d="M27.1295 44.3525C32.9791 40.8901 32.9793 29.663 27.1299 19.276C21.2805 8.8891 11.7967 3.27569 5.94707 6.73812C0.0974925 10.2006 0.0973212 21.4277 5.94669 31.8146C11.7961 42.2015 21.2799 47.815 27.1295 44.3525Z"
                fill="#14555A"
              />
              <path
                d="M16.6067 16.8013C19.2479 18.365 21.7109 21.5097 23.0352 24.9994L25.1617 24.8012C23.3994 20.1541 20.1216 15.9735 16.6067 13.8931C15.0377 12.9645 13.4933 12.4937 12.107 12.5134L11.2248 10.7768L9.52344 15.5169L14.5785 17.3784L13.77 15.7863C14.6639 15.8805 15.6284 16.2232 16.6067 16.8013Z"
                fill="#F2F2F5"
              />
              <path
                d="M11.1265 28.2646C9.88439 25.9192 9.18696 23.4051 9.18696 21.1944C9.18696 19.9143 9.41446 18.815 9.86196 17.9281L7.62976 15.391C7.03059 16.5725 6.73047 18.0375 6.73047 19.7402C6.73047 23.0022 7.869 26.759 9.87371 30.1557L9.20939 31.1554L14.3541 34.1403L11.7374 27.3514L11.1265 28.2646Z"
                fill="#F2F2F5"
              />
              <path
                d="M27.7143 33.8435L24.789 27.1018L22.5867 31.3032L23.8203 31.9153C23.0374 35.3601 20.0928 36.4365 16.6035 34.3713V37.2796C19.0696 38.7392 21.43 39.0556 23.2521 38.1698C24.9257 37.3562 26.0162 35.5944 26.3611 33.1745L27.7143 33.8435Z"
                fill="#F2F2F5"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="34" height="111" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      );

    case 'no-passing-sign':
      return (
        <div className={styles.roadSignIllustration}>
          <DefaultCarAndRoad />
          <svg
            className={styles.signPost}
            width="35"
            height="109"
            viewBox="0 0 35 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M19.2796 2.15682C20.3175 2.15682 21.1588 1.67398 21.1588 1.07835C21.1588 0.482727 20.3175 -0.00012207 19.2796 -0.00012207C18.2417 -0.00012207 17.4004 0.482727 17.4004 1.07835C17.4004 1.67398 18.2417 2.15682 19.2796 2.15682Z"
                fill="#28B482"
              />
              <path
                d="M17.4004 107.921C17.4004 108.517 18.2421 108.997 19.2796 108.997C20.3171 108.997 21.1588 108.514 21.1588 107.921V1.07835C21.1588 1.67404 20.3182 2.1536 19.2796 2.1536C18.241 2.1536 17.4004 1.67082 17.4004 1.07835V107.921Z"
                fill="#007272"
              />
              <path
                d="M32.995 32.9433C32.995 38.1045 31.171 41.7367 28.2218 43.4291C25.2726 45.1216 21.1972 44.8732 16.6977 42.2926C12.1982 39.712 8.1228 35.2788 5.17361 30.2025C2.22443 25.1263 0.400391 19.4049 0.400391 14.2394C0.400391 9.0739 2.22443 5.44601 5.17361 3.75357C8.1228 2.06113 12.1971 2.30521 16.6977 4.88796C21.1983 7.4707 25.2716 11.9018 28.2218 16.9791C31.1721 22.0564 32.995 27.7768 32.995 32.9433Z"
                fill="#DC2A2A"
              />
              <path
                d="M26.4739 40.4185C31.8725 37.3207 31.8724 27.2755 26.4736 17.9819C21.0749 8.68829 12.322 3.66558 6.92342 6.76336C1.52485 9.86114 1.52497 19.9063 6.92369 29.2C12.3224 38.4936 21.0754 43.5163 26.4739 40.4185Z"
                fill="#F2F2F5"
              />
              <path
                d="M6.57918 2.94713C9.52836 1.25469 13.6027 1.49877 18.1033 4.08152C22.6039 6.66426 26.6771 11.0954 29.6274 16.1727C32.5777 21.25 34.4006 26.9725 34.4006 32.1369C34.4006 37.3013 32.5766 40.9303 29.6274 42.6227L28.221 43.4291C31.1701 41.7367 32.9942 38.1077 32.9942 32.9433C32.9942 27.7789 31.1701 22.0554 28.221 16.9791C25.2718 11.9029 21.1974 7.4707 16.6979 4.88795C12.1984 2.30521 8.12301 2.06113 5.17383 3.75357L6.57918 2.94713Z"
                fill="#CCCCCC"
              />
              <path
                d="M17.5391 27.4112L18.12 27.7445V28.7122C18.1257 28.8186 18.1566 28.9222 18.2101 29.0145C18.2637 29.1068 18.3385 29.1852 18.4284 29.2434L18.9033 29.5165C19.0742 29.6133 19.2127 29.5348 19.2127 29.3391V28.3713L24.5204 31.4175V32.3852C24.5262 32.4916 24.5571 32.5951 24.6107 32.6874C24.6643 32.7797 24.739 32.8582 24.8287 32.9164L25.3047 33.1885C25.4746 33.2863 25.6131 33.2078 25.6131 33.0121V32.0444L26.194 32.3777V31.5745L17.5391 26.6079V27.4112Z"
                fill="#00343E"
              />
              <path
                d="M25.7037 28.6724L24.98 25.7338C24.8718 24.6144 23.0001 23.3747 21.8685 22.7231C20.7358 22.0779 18.8587 21.1575 18.756 22.1607L18.0333 24.2575C17.7271 24.351 17.541 24.6682 17.541 25.1263V26.2811L26.196 31.2476V30.0982C26.1949 29.6423 26.0067 29.1176 25.7037 28.6724ZM19.5317 25.9488C19.5317 26.1187 19.4386 26.2176 19.2991 26.1951L18.5926 26.0875C18.3632 26.052 18.1209 25.7176 18.1209 25.437V25.0994C18.1209 24.8757 18.2789 24.7854 18.4725 24.8972L19.18 25.3026C19.2823 25.3693 19.3673 25.4589 19.4284 25.5643C19.4895 25.6696 19.5248 25.7877 19.5317 25.909V25.9488ZM19.0535 24.3628L19.5663 22.8736C19.5741 22.8509 19.5795 22.8275 19.5825 22.8037C19.7448 22.7371 20.5259 22.9188 21.8674 23.6887C23.2089 24.4585 23.99 25.1725 24.1523 25.4252C24.1558 25.4556 24.1612 25.4858 24.1686 25.5155L24.6814 27.5929C22.8487 25.8908 20.8862 24.7661 19.0535 24.3628ZM25.6139 29.7326C25.6139 30.0122 25.3716 30.0692 25.1412 29.8401L24.4347 29.1412C24.2957 29.0049 24.2128 28.8221 24.2021 28.6283V28.5843C24.2021 28.3606 24.36 28.2703 24.5548 28.3821L25.2612 28.7875C25.3639 28.8538 25.4494 28.9433 25.5107 29.0487C25.5719 29.1541 25.6073 29.2724 25.6139 29.3939V29.7326Z"
                fill="#00343E"
              />
              <path
                d="M6.41797 21.1597L6.99893 21.493V22.4607C7.00459 22.5672 7.03547 22.6707 7.08905 22.763C7.14263 22.8554 7.2174 22.9338 7.30727 22.9919L7.78221 23.265C7.95314 23.3629 8.09163 23.2833 8.09163 23.0876V22.1199L13.3993 25.1661V26.1338C13.4051 26.2402 13.4361 26.3437 13.4896 26.436C13.5432 26.5282 13.6179 26.6067 13.7076 26.665L14.1836 26.937C14.3535 27.0348 14.492 26.9563 14.492 26.7606V25.7929L15.0729 26.1263V25.323L6.41797 20.3565V21.1597Z"
                fill="#DC2A2A"
              />
              <path
                d="M14.5826 22.4209L13.8589 19.4855C13.7561 18.3662 11.8791 17.1264 10.7474 16.4748C9.61469 15.8297 7.73764 14.9093 7.63486 15.9125L6.91217 18.0092C6.606 18.1027 6.41992 18.4199 6.41992 18.878V20.035L15.0749 25.0015V23.8467C15.0738 23.3908 14.8856 22.8661 14.5826 22.4209ZM8.41597 19.6973C8.41597 19.8672 8.32293 19.9661 8.18337 19.9446L7.47691 19.8371C7.24755 19.8006 7.00521 19.4662 7.00521 19.1855V18.8479C7.00521 18.6253 7.16317 18.5339 7.35682 18.6457L8.06437 19.0511C8.1666 19.1178 8.25166 19.2075 8.31272 19.3128C8.37379 19.4181 8.40915 19.5362 8.41597 19.6575V19.6973ZM7.93779 18.1113L8.4506 16.6221C8.4584 16.5994 8.46384 16.576 8.46682 16.5522C8.6291 16.4856 9.41021 16.6673 10.7517 17.4372C12.0933 18.207 12.869 18.921 13.0312 19.1748C13.0347 19.2048 13.0402 19.2346 13.0475 19.264L13.5603 21.3414C11.733 19.6393 9.76507 18.5135 7.93238 18.1113H7.93779ZM14.4983 23.4811C14.4983 23.7618 14.2559 23.8177 14.0255 23.5886L13.319 22.8897C13.18 22.7534 13.0971 22.5706 13.0864 22.3768V22.3328C13.0864 22.1091 13.2444 22.0188 13.4391 22.1306L14.1456 22.536C14.2482 22.6023 14.3337 22.6918 14.395 22.7972C14.4563 22.9026 14.4917 23.0209 14.4983 23.1424V23.4811Z"
                fill="#DC2A2A"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="34"
                  height="109"
                  fill="white"
                  transform="translate(0.400391 -0.00012207)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      );

    case 'passing':
      const passingCarStyle = {
        position: 'absolute',
        bottom: '258px',
        right: '378px',
      };

      const passingCarAStyle = {
        position: 'absolute',
        bottom: '153px',
        right: '684px',
      };

      const passingCarBStyle = {
        position: 'absolute',
        bottom: '168px',
        right: '528px',
      };

      return (
        <div className={classNames(styles.corner, styles.passingOuter)}>
          <Road autoplay />
          <Car autoplay carType={carA} style={passingCarAStyle} />
          <Car autoplay carType={carB} style={passingCarBStyle} />
          <Car autoplay style={passingCarStyle} />
        </div>
      );

    case 'intersection':
      const crossStyle = {
        position: 'absolute',
        bottom: '138px',
        right: '455px',
      };

      return (
        <div className={classNames(styles.intersectionOuter, styles.corner)}>
          <img
            className={classNames(styles.intersection, styles.corner)}
            src={crossingImage}
            alt="crossing"
          />
          <div>
            <svg
              className={styles.letters}
              id="A"
              width="34"
              height="20"
              viewBox="0 0 34 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0679 13.6729L9.70696 9.42068L4.82382 10.9369L0 8.18129L29.7205 0.0263672L33.5426 2.22824L19.4139 19.3687L14.4385 16.4944L17.0679 13.6729ZM19.7038 10.8644L25.7468 4.4104L14.5439 7.88462L19.7038 10.8644Z"
                fill="#28B482"
              />
            </svg>
            <svg
              className={styles.letters}
              id="B"
              width="34"
              height="21"
              viewBox="0 0 34 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.2363 18.5958C18.6646 20.6593 13.3399 20.5736 9.55072 18.398L0.00195312 12.8867L21.92 0.222504L30.2826 5.05482C34.197 7.31604 34.3815 10.0651 30.7834 12.1418C28.6874 13.3368 26.2451 13.7765 23.864 13.3877C25.2743 15.2006 24.7405 17.1718 22.2363 18.5958ZM17.8804 15.5764C19.541 14.6205 19.541 13.3152 17.4718 12.122L14.0912 10.1442L8.45681 13.4009L11.8374 15.3787C13.9067 16.618 16.0022 16.684 17.8804 15.5962V15.5764ZM22.8623 5.10753L17.8474 8.0478L20.0419 9.31355C22.0189 10.4672 24.0485 10.5793 25.6499 9.65637C27.2512 8.73342 27.0865 7.59293 25.0502 6.41947L22.8623 5.10753Z"
                fill="#28B482"
              />
            </svg>
            <svg
              className={styles.letters}
              id="C"
              width="32"
              height="19"
              viewBox="0 0 32 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.71933 3.86361C12.4542 -0.0259684 21.2847 -0.170994 27.578 3.46147C32.191 6.14462 33.1861 9.309 30.6819 11.8405L24.7905 10.5748C26.2008 8.63657 26.1085 7.2785 23.4725 5.78201C20.1776 3.86359 14.7936 4.16687 10.5366 6.63246C6.27947 9.09806 5.71933 12.1833 9.01428 14.1215C10.2724 14.7939 11.6556 15.1995 13.0775 15.313C14.4993 15.4264 15.9293 15.2452 17.278 14.7808L18.7212 18.4726C14.0667 19.6205 9.14844 18.9112 5.00761 16.4948C-1.27916 12.8492 -1.02873 7.75318 5.71933 3.86361Z"
                fill="#28B482"
              />
            </svg>
          </div>
          <Car autoplay style={crossStyle} />
        </div>
      );

    case 'give-way-sign':
      return (
        <div className={styles.roadSignIllustration}>
          <DefaultCarAndRoad autoplay={false} />
          <svg
            className={styles.signPost}
            width="35"
            height="126"
            viewBox="0 0 35 126"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M19.8471 7.49106C21 7.49106 21.9345 6.95594 21.9345 6.29584C21.9345 5.63573 21 5.10062 19.8471 5.10062C18.6943 5.10062 17.7598 5.63573 17.7598 6.29584C17.7598 6.95594 18.6943 7.49106 19.8471 7.49106Z"
                fill="#28B482"
              />
              <path
                d="M17.7588 124.705C17.7588 125.365 18.6937 125.897 19.8474 125.897C21.001 125.897 21.9348 125.361 21.9348 124.705V6.29585C21.9348 6.95602 20.9998 7.4875 19.8474 7.4875C18.6949 7.4875 17.7588 6.95245 17.7588 6.29585V124.705Z"
                fill="#007272"
              />
              <path
                d="M32.1111 17.4259L3.92841 1.291C2.86369 0.680878 1.93476 0.814343 1.43365 1.50431C0.575621 1.58653 0 2.32058 0 3.53964V35.8094C0 37.6362 1.29305 39.861 2.88412 40.7702L31.0668 56.9051C32.1315 57.514 33.0604 57.3817 33.5616 56.6918C34.4184 56.6096 34.9952 55.8743 34.9952 54.6564V22.3855C34.9988 20.5587 33.7058 18.3387 32.1111 17.4259Z"
                fill="#CCCCCC"
              />
              <path
                d="M32.9824 57.1708L34.195 56.4844L33.1302 56.0208L32.9824 57.1708Z"
                fill="#CCCCCC"
              />
              <path
                d="M2.88723 40.0444C1.64346 39.3294 0.632812 37.5956 0.632812 36.1728V3.90183C0.632812 2.479 1.64346 1.89986 2.88723 2.61127L31.0699 18.7462C32.3125 19.4611 33.3243 21.195 33.3243 22.619V54.8899C33.3243 56.3199 32.3125 56.8931 31.0699 56.1805L2.88723 40.0444Z"
                fill="#14555A"
              />
              <path
                d="M31.0707 19.4719C31.9648 19.9831 32.6918 21.2331 32.6918 22.2556V54.5325C32.6918 55.5561 31.9708 55.972 31.0707 55.4608L2.88803 39.3187C1.99395 38.8027 1.26691 37.5587 1.26691 36.5386V4.26528C1.26691 3.24166 1.98794 2.82459 2.88803 3.33699L31.0707 19.4719ZM31.0707 18.0216L2.88803 1.88676C1.29335 0.972769 0.00390625 1.71278 0.00390625 3.53957V35.8093C0.00390625 37.6361 1.29695 39.8609 2.88803 40.7701L31.0707 56.905C32.6654 57.8178 33.9548 57.0778 33.9548 55.2522V22.9813C33.9548 21.1545 32.6618 18.9297 31.0707 18.0216Z"
                fill="#FAFAFA"
              />
              <path
                d="M13.5403 31.2346V15.9374L10.1623 14.0034V29.3006L7.97754 28.0493L11.8507 37.9495L15.725 32.4858L13.5403 31.2346Z"
                fill="#DC2A2A"
              />
              <path
                d="M25.9821 30.7424L22.1077 20.8423L18.2334 26.3071L20.4181 27.5584V42.8555L23.7973 44.7895V29.4924L25.9821 30.7424Z"
                fill="#FAFAFA"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="35"
                  height="125"
                  fill="white"
                  transform="translate(0 0.900085)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      );

    case 'break-length':
      const measureStyle = {
        position: 'absolute',
        right: '397px',
        bottom: '266px',
      };

      return (
        <>
          <DefaultCarAndRoad autoplay={true} />
          <Animate
            autoplay={true}
            style={measureStyle}
            width={211}
            height={151}
            animation={measure}
          />
        </>
      );

    case 'lights':
      const carLightsStyle = {
        position: 'absolute',
        bottom: '198px',
        right: '357px',
      };

      return (
        <>
          <Road autoplay />
          <Animate
            autoplay={true}
            style={carLightsStyle}
            width={223}
            height={154}
            animation={carLights}
          />
        </>
      );

    case 'abort-passing':
      const abortPassingCarAStyle = {
        position: 'absolute',
        bottom: '120px',
        right: '610px',
      };

      const abortPassingCarBStyle = {
        position: 'absolute',
        bottom: '225px',
        right: '433px',
      };
      return (
        <div className={classNames(styles.abortPassingOuter, styles.corner)}>
          <Road autoplay />
          <Car autoplay carType={carA} style={abortPassingCarAStyle} />
          <Car autoplay carType={carB} style={abortPassingCarBStyle} />
          <svg
            className={styles.popUp}
            id="one"
            width="51"
            height="80"
            viewBox="0 0 51 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M50.0737 16.7308C49.7062 9.76624 46.6286 5.16314 41.9892 3.61166C40.1862 2.99563 32.406 1.33006 30.3964 0.99923C27.0374 0.480169 23.2937 1.13614 19.4869 3.11542C7.08448 9.53809 -1.5168 26.9751 0.406722 41.3948C0.782761 44.8238 1.96269 48.1175 3.85183 51.0116L3.88053 51.0459C4.26509 51.6061 4.68509 52.1414 5.138 52.6487L25.9866 79.1265C26.2223 79.4395 26.5586 79.6629 26.9398 79.7597C27.2168 79.826 27.5045 79.8349 27.7851 79.7859C28.0656 79.7368 28.333 79.6308 28.5705 79.4745L28.6681 79.4174C29.767 78.7054 30.5954 77.6496 31.0223 76.4172L47.0994 32.5593C47.4554 31.6752 47.777 30.7911 48.0755 29.9013V29.8499C49.5465 25.6358 50.2239 21.1886 50.0737 16.7308Z"
                fill="#007272"
              />
              <path
                d="M48.082 29.8218C49.553 25.6076 50.2303 21.1605 50.0801 16.7027C49.437 4.54749 40.52 -0.409261 29.5932 5.29471C17.277 11.7345 7.11963 28.8293 7.11963 42.8211C7.11963 46.5629 7.84886 49.6659 9.14652 52.1243V52.1585C9.4354 52.6938 9.76154 53.2084 10.1226 53.6986L26.1998 79.1212C27.2276 80.7411 29.9837 79.1554 31.0114 76.3605L47.0886 32.5027C47.4446 31.6185 47.7662 30.7344 48.0647 29.8446L48.082 29.8218Z"
                fill="#28B482"
              />
              <path
                d="M41.5207 38.5728C48.6495 26.307 48.6489 13.0488 41.5196 8.9598C34.3902 4.87082 22.8318 11.4995 15.7031 23.7653C8.57436 36.0312 8.5749 49.2893 15.7042 53.3783C22.8336 57.4673 34.392 50.8387 41.5207 38.5728Z"
                fill="#FBF6EC"
              />
              <path
                d="M34.4108 33.478V37.4024L22.8065 44.0418V40.1117L26.4296 38.0526V25.2187C25.3746 26.4696 24.1538 27.5729 22.8008 28.4985V24.5399C24.7186 23.2394 27.0957 20.2448 28.1694 17.6951L30.6614 16.2634V35.6569L34.4108 33.478Z"
                fill="#14555A"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="50"
                  height="79"
                  fill="white"
                  transform="translate(0.108887 0.799988)"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            className={styles.popUp}
            id="two"
            width="51"
            height="79"
            viewBox="0 0 51 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M50.904 15.9312C50.5365 8.96663 47.4589 4.36352 42.8195 2.81204C41.0165 2.19602 33.2421 0.530447 31.2267 0.199616C27.8735 -0.319445 24.124 0.336524 20.3172 2.3158C7.89184 8.70995 -0.720945 26.1299 1.21406 40.5666C1.5901 43.9957 2.77003 47.2894 4.65916 50.1835V50.2177C5.04567 50.778 5.4676 51.3134 5.92238 51.8206L26.8399 78.2984C27.0789 78.6105 27.4167 78.8335 27.7988 78.9315C28.0757 78.9979 28.3635 79.0068 28.6441 78.9577C28.9246 78.9087 29.192 78.8027 29.4295 78.6463L29.5271 78.5893C30.6241 77.8766 31.4505 76.8208 31.8755 75.589L47.9527 31.7312C48.303 30.8471 48.6302 29.9629 48.9231 29.0731V29.0218C50.385 24.8156 51.0564 20.3784 50.904 15.9312Z"
                fill="#007272"
              />
              <path
                d="M48.9173 29.0218C50.3866 24.8074 51.062 20.3601 50.9097 15.9027C50.2724 3.7475 41.3553 -1.20925 30.4228 4.49472C18.1066 10.9345 7.94922 28.0293 7.94922 42.0211C7.94922 45.7629 8.6842 48.8659 9.98186 51.3243V51.3585C10.2688 51.8937 10.593 52.4083 10.9522 52.8986L27.0294 78.3212C28.0514 79.9411 30.8133 78.3554 31.8353 75.5605L47.9125 31.7027C48.2627 30.8186 48.59 29.9344 48.8829 29.0446L48.9173 29.0218Z"
                fill="#28B482"
              />
              <path
                d="M42.3572 37.7715C49.4859 25.5056 49.4854 12.2474 42.356 8.15843C35.2267 4.06945 23.6682 10.6981 16.5395 22.964C9.41079 35.2298 9.41133 48.488 16.5407 52.577C23.67 56.6659 35.2285 50.0373 42.3572 37.7715Z"
                fill="#FBF6EC"
              />
              <path
                d="M28.8325 36.3514L36.1132 32.1761V36.1061L22.7749 43.7552V40.7264L27.2708 32.661C30.1417 27.6015 32.0824 24.6411 32.0824 21.9774C32.0824 19.9468 31.0087 19.365 29.4469 20.2662C27.9942 21.099 26.8631 22.776 26.4267 25.0575L22.8323 25.6279C23.182 23.6937 23.9558 21.8596 25.0989 20.2558C26.2421 18.6519 27.7265 17.3176 29.4469 16.3476C33.4203 14.066 36.1477 15.4293 36.1477 19.6559C36.1477 23.7514 32.7485 29.3527 30.1991 33.9158L28.8325 36.3514Z"
                fill="#14555A"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="50"
                  height="79"
                  fill="white"
                  transform="translate(0.944336)"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            className={styles.popUp}
            id="three"
            width="51"
            height="80"
            viewBox="0 0 51 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M50.0272 16.1305C49.6597 9.1659 46.5821 4.5628 41.9426 3.01132C40.1397 2.39529 32.3652 0.729719 30.3499 0.398889C26.9966 -0.120173 23.2472 0.535797 19.4403 2.51507C7.03796 8.93775 -1.56906 26.3748 0.360203 40.7944C0.736242 44.2235 1.91617 47.5172 3.80531 50.4113V50.4456C4.19182 51.0058 4.61371 51.5412 5.06849 52.0484L25.9114 78.5262C26.147 78.8392 26.4834 79.0626 26.8646 79.1593C27.1415 79.2257 27.4292 79.2346 27.7098 79.1855C27.9904 79.1364 28.2578 79.0304 28.4953 78.8741C28.5296 78.8584 28.5623 78.8393 28.5929 78.8171C29.6917 78.105 30.5201 77.0492 30.947 75.8168L47.0242 31.959C47.3744 31.0749 47.7017 30.1908 47.9945 29.3009V29.2496C49.4747 25.037 50.1637 20.5902 50.0272 16.1305Z"
                fill="#007272"
              />
              <path
                d="M48.0413 29.2218C49.5078 25.0067 50.1831 20.56 50.0337 16.1027C49.3906 3.94751 40.4735 -1.00924 29.5468 4.69473C17.2305 11.1345 7.07324 28.2293 7.07324 42.2211C7.07324 45.963 7.80244 49.0659 9.10584 51.5243V51.5585C9.39278 52.0937 9.71703 52.6084 10.0762 53.0986L26.1534 78.5212C27.1754 80.1412 29.9373 78.5554 30.9593 75.7605L47.0365 31.9027C47.3867 31.0186 47.714 30.1344 48.0069 29.2446L48.0413 29.2218Z"
                fill="#28B482"
              />
              <path
                d="M41.4793 37.975C48.608 25.7092 48.6074 12.451 41.4781 8.36202C34.3487 4.27304 22.7903 10.9017 15.6616 23.1675C8.53287 35.4333 8.5334 48.6916 15.6627 52.7805C22.7921 56.8695 34.3506 50.2409 41.4793 37.975Z"
                fill="#FBF6EC"
              />
              <path
                d="M35.9665 28.8682C35.9665 32.9579 33.0038 37.8861 28.5653 40.4301C24.5058 42.7573 22.1287 41.896 21.2847 38.9813L24.9996 35.2224C25.436 37.1675 26.7681 37.504 28.5653 36.5058C30.1616 35.5875 31.7291 33.3572 31.7291 31.2981C31.7291 29.3359 30.3338 28.6001 28.4218 29.7009L26.7394 30.6649V26.9003L28.4218 25.9363C29.1376 25.487 29.7279 24.8659 30.1384 24.1302C30.5488 23.3945 30.7661 22.5679 30.7702 21.7268C30.7702 20.0156 29.5529 19.8274 28.3356 20.529C27.5517 20.9971 26.8823 21.6325 26.3759 22.3891C25.8695 23.1457 25.5386 24.0047 25.4073 24.9039L21.9622 25.7652C22.2433 23.8688 22.9636 22.0631 24.0666 20.4903C25.1696 18.9175 26.6251 17.6206 28.3184 16.7016C31.9415 14.6197 34.9043 14.8535 34.9043 18.9832C34.8786 20.1715 34.6168 21.3431 34.134 22.4306C33.6512 23.5181 32.9568 24.5001 32.0908 25.3203C34.5713 24.5446 35.9665 25.6055 35.9665 28.8682Z"
                fill="#14555A"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="50"
                  height="79"
                  fill="white"
                  transform="translate(0.0683594 0.200012)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      );

    case 'all-vehicles-forbidden-sign':
      return (
        <div className={styles.roadSignIllustration}>
          <DefaultCarAndRoad autoplay={false} />
          <svg
            className={styles.signPost}
            width="35"
            height="110"
            viewBox="0 0 35 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M18.9798 2.35702C20.0177 2.35702 20.859 1.87417 20.859 1.27855C20.859 0.682922 20.0177 0.200073 18.9798 0.200073C17.9419 0.200073 17.1006 0.682922 17.1006 1.27855C17.1006 1.87417 17.9419 2.35702 18.9798 2.35702Z"
                fill="#28B482"
              />
              <path
                d="M17.1008 108.122C17.1008 108.717 17.9414 109.197 18.98 109.197C20.0186 109.197 20.8593 108.714 20.8593 108.122V1.27855C20.8593 1.87424 20.0176 2.3538 18.98 2.3538C17.9425 2.3538 17.1008 1.87101 17.1008 1.27855V108.122Z"
                fill="#007272"
              />
              <path
                d="M32.6947 33.1425C32.6947 38.3037 30.8707 41.9369 27.9215 43.6294C24.9723 45.3218 20.898 45.0766 16.3974 42.495C11.8968 39.9133 7.8225 35.4811 4.87332 30.4038C1.92413 25.3265 0.100098 19.604 0.100098 14.4428C0.100098 9.28164 1.92413 5.64838 4.87332 3.95594C7.8225 2.2635 11.899 2.50758 16.3974 5.08818C20.8958 7.66877 24.9723 12.102 27.9215 17.1793C30.8707 22.2567 32.6947 27.9781 32.6947 33.1425Z"
                fill="#DC2A2A"
              />
              <path
                d="M26.1727 40.6184C31.5712 37.5206 31.5711 27.4754 26.1724 18.1818C20.7737 8.8882 12.0207 3.86548 6.62215 6.96326C1.22358 10.061 1.2237 20.1063 6.62242 29.3999C12.0211 38.6935 20.7741 43.7162 26.1727 40.6184Z"
                fill="#F2F2F5"
              />
              <path
                d="M6.27864 3.14626C9.22783 1.45382 13.3022 1.69898 17.8028 4.28065C22.3033 6.86232 26.3777 11.2945 29.3269 16.3718C32.276 21.4491 34.1001 27.1716 34.1001 32.336C34.1001 37.5004 32.276 41.1305 29.3269 42.8229L27.9204 43.6293C30.8696 41.9369 32.6936 38.3079 32.6936 33.1424C32.6936 27.977 30.8707 22.2556 27.9215 17.1782C24.9723 12.1009 20.898 7.66876 16.3974 5.08816C11.8968 2.50757 7.82248 2.26133 4.87329 3.95377L6.27864 3.14626Z"
                fill="#CCCCCC"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="34"
                  height="109"
                  fill="white"
                  transform="translate(0.100098 0.200073)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      );

    default:
      return <div>{type}</div>;
  }
};

export default AnimationSwitch;
