// extracted by mini-css-extract-plugin
export var question = "Question-module--question--JfKmf";
export var constraint = "Question-module--constraint--3j3XX";
export var heading = "Question-module--heading--3sciz";
export var answerList = "Question-module--answerList--3HpZU";
export var optionButton = "Question-module--optionButton--2SBsx";
export var answered = "Question-module--answered--1wzJJ";
export var selected = "Question-module--selected--3c6qY";
export var correct = "Question-module--correct--1Jniq";
export var wrong = "Question-module--wrong--VOYmY";
export var buttons = "Question-module--buttons--3g-j_";
export var illustrationToggle = "Question-module--illustrationToggle--31DGN";
export var contentOuter = "Question-module--contentOuter--2EQBc";
export var hide = "Question-module--hide--1reiy";
export var illustrationLeftBox = "Question-module--illustrationLeftBox--34Uiw";
export var show = "Question-module--show--2OAzQ";