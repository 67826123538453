import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import * as styles from './Quiz.module.scss';
import { StaticQuery, graphql } from 'gatsby';
import Question from '../question/Question';
import QuizHeader from '../quiz-header/QuizHeader';
import { StatusContext } from '../../contexts/StatusProvider';
import { navigate } from 'gatsby';
import Loading from '../loading/Loading';
import AnimationSwitch from '../animation-switch/AnimationSwitch';
import { pageLoad } from '../../utils/track';

const Quiz = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityQuizpage {
            quizArray {
              _id
              questionText
              questionAnswers {
                _key
                answerCorrect
                answerAlternative
              }
              illustration
              illustrationLeft
            }
          }
          sanityResultpage {
            resultArray {
              _key
              score
            }
          }
        }
      `}
      render={(data) => {
        const quizData = data.sanityQuizpage;
        const resultKeys = data.sanityResultpage.resultArray;
        return (
          <QuizSection
            title={quizData.title}
            questions={quizData.quizArray}
            resultKeys={resultKeys}
          />
        );
      }}
    />
  );
};

const QuizSection = ({ questions, resultKeys }) => {
  const [buttonHolding, setButtonHolding] = useState(false);
  const [status] = useContext(StatusContext);

  useEffect(() => {
    // Tracking
    pageLoad();
  }, []);

  useEffect(() => {
    const resultData = resultKeys.find((o) => o.score === status.score);
    if (status.question === questions.length) {
      if (resultData) {
        navigate(`/${resultData._key}`);
      } else {
        console.log('No result-page avaliable for score: ' + status.score);
        navigate(`/`);
      }
    }
  }, [status, questions, resultKeys]);

  if (!status) {
    return <Loading />;
  }

  const exitVariants = {
    default: {
      opacity: 0,
      x: -300,
      position: 'absolute',
    },
    final: {
      opacity: 0,
      x: 0,
      position: 'static',
    },
  };

  return (
    <section className={styles.quiz}>
      <div className={styles.questions}>
        <QuizHeader
          currentQuestion={status.question}
          totalQuestion={questions.length}
        />
        <AnimatePresence initial={false}>
          {questions.map((question, index) => {
            const { _id, questionText, questionAnswers, illustrationLeft } =
              question;
            if (status.question === index) {
              return (
                <motion.div
                  variants={exitVariants}
                  initial={{ opacity: 0, x: 300, position: 'absolute' }}
                  animate={{ opacity: 1, x: 0, position: 'static' }}
                  exit={questions.length === index + 1 ? 'final' : 'default'}
                  transition={{ duration: 0.3 }}
                  key={_id}
                >
                  <Question
                    buttonHolding={buttonHolding}
                    setButtonHolding={setButtonHolding}
                    question={questionText}
                    answers={questionAnswers}
                    illustrationLeft={illustrationLeft}
                  />
                </motion.div>
              );
            } else {
              return null;
            }
          })}
        </AnimatePresence>
      </div>
      <div
        className={classNames(styles.animationBox, {
          [styles.show]: buttonHolding,
        })}
      >
        {questions.map((question, index) => {
          const { illustration } = question;
          if (status.question === index) {
            return <AnimationSwitch key={index} type={illustration} />;
          } else {
            return null;
          }
        })}
      </div>
    </section>
  );
};

export default Quiz;
