import React from 'react';
import Lottie from 'react-lottie';
import { object, number } from 'prop-types';

const Animate = ({
  autoplay = false,
  animation,
  width = 400,
  height = 400,
  style,
}) => {
  const options = {
    loop: true,
    autoplay: autoplay,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie style={style} width={width} height={height} options={options} />
  );
};

Animate.propTypes = {
  animation: object,
  width: number,
  height: number,
  style: object,
};

export default Animate;
