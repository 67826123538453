import React from 'react';
import SpriteSheetAnimater from '../sprite-sheet-animater/SpriteSheet';
import clockSpriteSheet from '../../images/clock-sprite-sheet.png';

const Illustration = ({ type }) => {
  if (!type) return null;

  switch (type) {
    case 'none':
      return <div>default</div>;

    case 'all-vehicles-forbidden-sign':
      return (
        <svg
          width="247"
          height="285"
          viewBox="0 0 247 285"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M20.5488 201.894C20.5488 234.674 32.0714 257.75 50.7016 268.499C69.3317 279.248 95.0695 277.691 123.5 261.294C151.93 244.897 177.668 216.748 196.298 184.501C214.929 152.253 226.451 115.909 226.451 83.1287C226.451 50.3488 214.929 27.2731 196.298 16.524C177.668 5.77492 151.917 7.32513 123.5 23.7151C95.0832 40.105 69.3317 68.2616 50.7016 100.509C32.0714 132.756 20.5488 169.094 20.5488 201.894Z"
              fill="#DC2A2A"
            />
            <path
              d="M61.7491 249.376C27.646 229.701 27.6468 165.902 61.7509 106.876C95.855 47.8499 151.148 15.9495 185.251 35.6242C219.354 55.2989 219.353 119.098 185.249 178.124C151.145 237.15 95.8522 269.05 61.7491 249.376Z"
              fill="#F2F2F5"
            />
            <path
              d="M187.421 11.3816C168.79 0.632545 143.053 2.18959 114.622 18.5864C86.1917 34.9831 60.4539 63.1329 41.8237 95.3801C23.1935 127.627 11.671 163.972 11.671 196.772C11.671 229.573 23.1935 252.628 41.8237 263.377L50.7082 268.499C32.0781 257.75 20.5555 234.702 20.5555 201.894C20.5555 169.087 32.0713 132.749 50.7014 100.502C69.3316 68.2547 95.0694 40.105 123.5 23.7151C151.93 7.32511 177.668 5.76123 196.298 16.5103L187.421 11.3816Z"
              fill="#CCCCCC"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="247" height="285" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case 'give-way-sign':
      return (
        <svg
          width="175"
          height="283"
          viewBox="0 0 175 283"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3936 82.9258L154.873 1.95464C160.18 -1.10719 164.811 -0.437412 167.308 3.02509C171.579 3.43772 174.454 7.12149 174.454 13.2392V175.181C174.454 184.349 168.009 195.514 160.079 200.077L19.5808 281.048C14.2798 284.104 9.64365 283.44 7.14588 279.977C2.87512 279.565 0 275.875 0 269.763V107.815C0 98.6476 6.44506 87.5066 14.3936 82.9258Z"
            fill="#CCCCCC"
          />
          <path
            d="M170.771 4.29298L164.942 0.920181L165.326 6.1528L170.771 4.29298Z"
            fill="#CCCCCC"
          />
          <path
            d="M10.0514 282.381L4.01367 278.937L9.31469 276.611L10.0514 282.381Z"
            fill="#CCCCCC"
          />
          <path
            d="M160.061 196.435C166.254 192.847 171.298 184.146 171.298 177.005V15.0572C171.298 7.91691 166.254 5.01056 160.061 8.5807L19.5807 89.5518C13.3872 93.1399 8.34375 101.841 8.34375 108.987V270.935C8.34375 278.112 13.3872 280.988 19.5807 277.412L160.061 196.435Z"
            fill="#14555A"
          />
          <path
            d="M19.5813 93.1937L160.061 12.2226C164.518 9.65115 168.141 11.7442 168.141 16.8811V178.841C168.141 183.978 164.547 190.245 160.061 192.811L19.5813 273.77C15.1308 276.318 11.483 274.248 11.483 269.141V107.163C11.483 102.032 15.1068 95.7592 19.5573 93.1937H19.5813ZM19.5573 85.9159C11.6087 90.4967 5.18164 101.638 5.18164 110.805V272.73C5.18164 281.891 11.6267 285.605 19.5573 281.024L160.037 200.053C167.986 195.472 174.413 184.325 174.413 175.158V13.2392C174.413 4.07168 167.968 0.358008 160.037 4.94478L19.5813 85.9159H19.5573Z"
            fill="#FAFAFA"
          />
          <path
            d="M123.804 99.4489V176.216L106.961 185.922V109.155L96.0713 115.428L115.383 65.7509L134.694 93.1698L123.804 99.4489Z"
            fill="#FAFAFA"
          />
          <path
            d="M83.5638 170.565L64.2585 220.248L44.9473 192.823L55.8368 186.55V109.783L72.6743 100.071V176.844L83.5638 170.565Z"
            fill="#DC2A2A"
          />
        </svg>
      );

    case 'insurance':
      return (
        <svg
          width="185"
          height="379"
          viewBox="0 0 185 379"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M180.837 4.90023L175.216 1.62994L175.061 1.53772C173.214 0.445253 170.797 0.537481 168.155 2.07686L11.2536 93.5389C5.45729 96.9156 0.714844 105.989 0.714844 113.799V365.96C0.714844 369.748 1.82494 372.536 3.62356 374.026C4.07423 374.399 4.58199 374.696 5.12706 374.905L9.74304 377.601C10.1652 377.953 10.6393 378.235 11.1482 378.438C12.8695 379.105 14.9844 378.857 17.2748 377.523L174.246 286.054C180.05 282.67 184.785 273.604 184.785 265.794V13.6329C184.75 9.17787 183.218 6.09911 180.837 4.90023Z"
              fill="#14555A"
            />
            <path
              d="M174.246 286.054L17.3376 377.495C11.5413 380.879 6.83398 377.289 6.83398 369.479V117.346C6.83398 109.543 11.5413 100.463 17.3376 97.0859L174.246 5.61677C180.049 2.23296 184.785 5.82248 184.785 13.6329V265.794C184.75 273.604 180.049 282.67 174.246 286.054Z"
              fill="#007272"
            />
            <path
              d="M172.665 17.1302L17.9209 107.337V362.718L24.0053 366.265L178.75 276.059V20.6772L172.665 17.1302Z"
              fill="#CCCCCC"
            />
            <path
              d="M178.75 276.059L24.0059 366.265V110.884L178.75 20.6772V276.059Z"
              fill="#F2F2F5"
            />
            <path
              d="M162.654 53.6285L42.8984 123.433V119.992L162.654 50.1879V53.6285Z"
              fill="#CCCCCC"
            />
            <path
              d="M162.654 66.6458L42.8984 136.457V133.017L162.654 63.2124V66.6458Z"
              fill="#CCCCCC"
            />
            <path
              d="M162.654 79.6703L42.8984 149.482V146.041L162.654 76.2368V79.6703Z"
              fill="#CCCCCC"
            />
            <path
              d="M162.654 92.6947L42.8984 162.506V159.066L162.654 89.2542V92.6947Z"
              fill="#CCCCCC"
            />
            <path
              d="M162.654 216.86L42.8984 286.671V283.231L162.654 213.426V216.86Z"
              fill="#CCCCCC"
            />
            <path
              d="M162.654 229.884L42.8984 299.696V296.255L162.654 226.444V229.884Z"
              fill="#CCCCCC"
            />
            <path
              d="M84.1331 296.801L42.8984 320.836V317.402L84.1331 293.361V296.801Z"
              fill="#CCCCCC"
            />
            <path
              d="M84.1331 309.826L42.8984 333.86V330.419L84.1331 306.385V309.826Z"
              fill="#CCCCCC"
            />
            <path
              d="M118.173 206.453V205.29C118.173 202.835 119.164 198.196 124.131 190.243C132.45 176.857 141.632 163.684 141.632 151.227C141.632 142.714 139.082 137.308 134.712 134.868L129.042 131.655C128.285 131.079 127.459 130.602 126.583 130.236L126.506 130.193C121.047 128.015 113.241 129.725 104.185 135.01C86.3109 145.424 78.2453 161.421 75.6387 173.934L82.5521 177.971L96.8286 176.595C99.5617 167.188 105.021 160.094 111.105 156.554C112.63 155.619 114.289 154.927 116.023 154.504C116.157 155.16 116.223 155.828 116.22 156.498C116.22 163.733 112.243 168.657 104.276 181.972C96.9481 194.195 94.0956 203.821 94.0956 209.901V212.362L99.3158 215.398C95.0089 219.832 91.8613 226.571 91.8613 232.183C91.8613 235.921 93.2665 238.525 95.4515 239.681L102.098 243.554L102.253 243.639L102.323 243.689C104.388 244.803 107.171 244.632 110.199 242.866C116.41 239.248 121.623 230.133 121.623 222.897C121.623 219.094 120.168 216.462 117.899 215.335L111.372 211.518L111.281 211.497L111.218 211.461C110.917 211.296 110.602 211.161 110.276 211.057L118.173 206.453Z"
              fill="#007272"
            />
            <path
              d="M101.044 216.463V214.001C101.044 207.929 103.896 198.302 111.217 186.072C119.164 172.757 123.161 167.841 123.161 160.605C123.161 154.22 118.328 152.426 111.126 156.625C105.042 160.172 99.5826 167.266 96.8496 176.666L82.5449 178.013C85.1515 165.493 93.2242 149.503 111.098 139.082C128.726 128.81 141.632 131.988 141.632 151.241C141.632 163.684 132.45 176.857 124.131 190.258C119.164 198.217 118.173 202.849 118.173 205.304V206.467L101.044 216.463ZM98.8098 236.29C98.8098 229.054 104.023 219.974 110.227 216.321C116.431 212.667 121.651 215.739 121.651 222.975C121.651 230.211 116.438 239.326 110.227 242.944C104.016 246.562 98.8098 243.497 98.8098 236.262V236.29Z"
              fill="#28B482"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="184"
                height="378"
                fill="white"
                transform="translate(0.75 0.799988)"
              />
            </clipPath>
          </defs>
        </svg>
      );

    case 'no-passing-sign':
      return (
        <svg
          width="265"
          height="286"
          viewBox="0 0 265 286"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M22.6621 202.604C22.6621 235.499 34.9777 258.649 54.8901 269.436C74.8025 280.223 102.312 278.64 132.736 262.192C163.159 245.745 190.632 217.489 210.544 185.136C230.457 152.782 242.772 116.303 242.772 83.3809C242.772 50.4586 230.457 27.3362 210.544 16.5494C190.632 5.76261 163.086 7.33197 132.736 23.7931C102.385 40.2543 74.839 68.4959 54.9266 100.856C35.0142 133.217 22.6621 169.675 22.6621 202.604Z"
              fill="#DC2A2A"
            />
            <path
              d="M198.699 178.749C235.151 119.516 235.151 55.493 198.701 35.7492C162.251 16.0055 103.152 48.0178 66.7011 107.251C30.2498 166.484 30.249 230.507 66.6993 250.251C103.15 269.995 162.248 237.982 198.699 178.749Z"
              fill="#F2F2F5"
            />
            <path
              d="M201.02 11.4233C181.107 0.636503 153.598 2.19216 123.24 18.6533C92.8819 35.1145 65.3142 63.3561 45.4018 95.7165C25.4894 128.077 13.1738 164.549 13.1738 197.465C13.1738 230.38 25.4894 253.509 45.4018 264.296L54.8978 269.436C34.9854 258.649 22.6698 235.52 22.6698 202.604C22.6698 169.689 34.9854 133.21 54.8978 100.856C74.8102 68.5028 102.312 40.2543 132.736 23.7932C163.16 7.33199 190.632 5.77633 210.545 16.5631L201.02 11.4233Z"
              fill="#CCCCCC"
            />
            <path
              d="M127.017 167.345L123.094 169.47V175.637C123.055 176.315 122.846 176.975 122.485 177.563C122.123 178.152 121.619 178.652 121.013 179.023L117.799 180.764C116.652 181.38 115.717 180.88 115.717 179.633V173.465L79.8804 192.88V199.048C79.841 199.726 79.632 200.385 79.2704 200.974C78.9087 201.562 78.4045 202.062 77.7985 202.433L74.5845 204.167C73.4377 204.791 72.5027 204.29 72.5027 203.043V196.875L68.5801 199V193.88L127.017 162.226V167.345Z"
              fill="#DC2A2A"
            />
            <path
              d="M68.5801 184.471V191.831L127.017 160.177V152.817C127.017 149.897 125.761 147.876 123.686 147.279L118.814 133.916C118.084 127.522 105.447 133.361 97.7986 137.5C90.1579 141.612 77.4844 149.541 76.7905 156.689L71.9037 175.398C69.8896 178.06 68.7357 181.21 68.5801 184.471ZM113.569 157.744C113.618 156.977 113.858 156.231 114.269 155.565C114.68 154.9 115.251 154.335 115.936 153.913L120.713 151.329C122.021 150.644 123.087 151.192 123.087 152.618V154.77C123.087 156.558 121.451 158.69 119.902 158.916L115.125 159.601C114.19 159.745 113.562 159.115 113.562 158.032L113.569 157.744ZM78.7992 168.503L82.2617 155.284C82.3111 155.094 82.3476 154.902 82.3712 154.708C83.4669 153.098 88.7408 148.54 97.7986 143.64C106.856 138.74 112.13 137.575 113.226 138C113.246 138.152 113.283 138.301 113.336 138.446L116.798 147.937C104.424 150.487 91.1733 157.655 78.7992 168.503ZM72.5027 179.989C72.5472 179.215 72.7862 178.461 73.2 177.789C73.6138 177.117 74.1908 176.547 74.884 176.124L79.6539 173.54C80.9687 172.855 82.0279 173.403 82.0279 174.829V175.11C81.9556 176.343 81.3986 177.508 80.4647 178.379L75.6875 182.833C74.1389 184.286 72.5027 183.923 72.5027 182.148V179.989Z"
              fill="#DC2A2A"
            />
            <path
              d="M202.13 127.501L198.208 129.626V135.793C198.168 136.471 197.959 137.131 197.598 137.719C197.236 138.308 196.732 138.808 196.126 139.179L192.912 140.92C191.765 141.543 190.83 141.036 190.83 139.789V133.621L154.994 153.036V159.204C154.954 159.882 154.745 160.541 154.384 161.13C154.022 161.718 153.518 162.218 152.912 162.589L149.698 164.323C148.551 164.947 147.616 164.446 147.616 163.199V157.031L143.693 159.156V154.036L202.13 122.389V127.501Z"
              fill="#00343E"
            />
            <path
              d="M143.693 144.627V151.987L202.13 120.333V112.973C202.13 110.053 200.874 108.032 198.799 107.435L193.927 94.0717C193.197 87.6778 180.56 93.5167 172.912 97.6559C165.271 101.768 152.598 109.697 151.904 116.845L147.017 135.554C145.003 138.216 143.849 141.366 143.693 144.627ZM188.683 117.9C188.727 117.126 188.965 116.373 189.377 115.701C189.79 115.03 190.365 114.459 191.057 114.035L195.834 111.451C197.141 110.766 198.208 111.321 198.208 112.74V114.891C198.208 116.68 196.572 118.811 195.023 119.044L190.246 119.73C189.311 119.867 188.683 119.236 188.683 118.154V117.9ZM153.92 128.659L157.382 115.419C157.432 115.232 157.468 115.042 157.492 114.85C158.587 113.233 163.861 108.683 172.919 103.776C181.977 98.8689 187.251 97.7108 188.347 98.1356C188.367 98.2873 188.403 98.4365 188.456 98.5811L191.904 108.073C179.486 110.636 166.257 117.811 153.92 128.659ZM147.623 140.145C147.668 139.371 147.907 138.617 148.321 137.945C148.734 137.273 149.311 136.703 150.005 136.28L154.774 133.696C156.089 133.011 157.148 133.559 157.148 134.985V135.266C157.076 136.499 156.519 137.664 155.585 138.535L150.808 142.989C149.259 144.442 147.623 144.086 147.623 142.304V140.145Z"
              fill="#00343E"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="264"
                height="286"
                fill="white"
                transform="translate(0.700195)"
              />
            </clipPath>
          </defs>
        </svg>
      );

    case 'parking-sign':
      return (
        <svg
          width="197"
          height="310"
          viewBox="0 0 197 310"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M17.9729 88.5892L167.477 2.41051C172.146 -0.252818 176.34 -0.532459 179.408 1.19205L190.845 7.78379C194.026 9.50164 196.007 13.3368 196.007 18.8499V191.214C196.007 202.021 188.411 215.184 179.035 220.564L29.5235 306.743C24.8552 309.406 20.6805 309.692 17.6128 307.975L6.02883 301.316C2.91443 299.565 0.980469 295.763 0.980469 290.323V117.946C1.00048 107.139 8.60306 93.9958 17.9729 88.5892Z"
              fill="#CCCCCC"
            />
            <path
              d="M29.5224 300.803C23.0069 304.559 17.7051 301.503 17.7051 293.985V121.635C17.7051 114.117 23.0069 104.949 29.5224 101.193L179.027 15.0147C185.542 11.2594 190.844 14.3156 190.844 21.8262V194.184C190.844 201.694 185.509 210.869 179.027 214.625L29.5224 300.803Z"
              fill="#14555A"
            />
            <path
              d="M179.028 20.9539C182.702 18.8366 185.697 20.5611 185.697 24.7958V197.153C185.697 201.395 182.709 206.561 179.028 208.685L29.5232 294.858C25.8486 296.982 22.8543 295.257 22.8543 291.016V118.658C22.8543 114.424 25.842 109.25 29.5232 107.133L179.028 20.9539ZM179.028 9.06882L29.5232 95.2475C20.1867 100.654 12.5508 113.798 12.5508 124.604V296.962C12.5508 307.768 20.1533 312.149 29.5232 306.743L179.028 220.564C188.404 215.164 196 202.02 196 191.214V18.8566C196 8.0501 188.404 3.66892 179.028 9.06882Z"
              fill="#F2F2F5"
            />
            <path
              d="M138.494 117.633C138.494 138.34 126.87 157.07 106.923 168.569L89.8837 178.39V219.505L70.0703 230.924V124.331L106.923 103.091C126.863 91.5922 138.494 96.9188 138.494 117.633ZM118.414 129.205C118.414 119.304 113.792 117.4 103.742 123.213L89.8704 131.203V160.113L103.742 152.123C113.819 146.337 118.407 139.099 118.407 129.205H118.414Z"
              fill="#F2F2F5"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="197"
                height="309"
                fill="white"
                transform="translate(0 0.100067)"
              />
            </clipPath>
          </defs>
        </svg>
      );

    case 'roundabout-sign':
      return (
        <svg
          width="301"
          height="346"
          viewBox="0 0 301 346"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M225.753 43.2527L219.801 39.8318C178.249 15.947 110.869 54.6717 69.2886 126.333C27.7086 197.994 27.7292 275.443 69.2886 299.335L75.2404 302.755C116.8 326.64 184.18 287.909 225.753 216.255C267.326 144.6 267.312 67.1374 225.753 43.2527Z"
              fill="#CCCCCC"
            />
            <path
              d="M225.751 216.25C267.31 144.592 267.309 67.137 225.749 43.2504C184.19 19.3639 116.809 58.0908 75.2495 129.749C33.6903 201.408 33.6908 278.863 75.2505 302.749C116.81 326.636 184.191 287.909 225.751 216.25Z"
              fill="#F2F2F5"
            />
            <path
              d="M218.656 212.173C256.297 147.272 256.295 77.1205 218.654 55.486C181.013 33.8515 119.985 68.926 82.3448 133.827C44.7047 198.728 44.7058 268.88 82.3472 290.514C119.989 312.149 181.016 277.074 218.656 212.173Z"
              fill="#14555A"
            />
            <path
              d="M150.943 117.863C167.939 108.092 183.788 109.502 192.31 121.53L205.994 104.569C194.661 88.5591 173.561 86.6845 150.943 99.6839C140.847 105.493 130.909 113.97 121.988 124.349L116.312 120.025L105.363 162.232L137.892 136.479L132.689 132.511C138.042 126.787 144.188 121.854 150.943 117.863Z"
              fill="#F2F2F5"
            />
            <path
              d="M115.694 230.007C107.694 224.533 103.199 214.01 103.199 200.197C103.305 191.556 104.768 182.984 107.536 174.793L93.1716 175.477C89.3366 187.28 87.3848 198.664 87.3848 209.303C87.3848 229.685 94.718 244.73 107.611 251.127L103.336 262.293L136.463 242.869L119.632 219.778L115.694 230.007Z"
              fill="#F2F2F5"
            />
            <path
              d="M222.427 142.178L203.595 121.721L189.431 164.257L197.382 158.934C192.345 186.246 173.403 214.756 150.943 227.66V245.839C166.812 236.718 182.008 221.208 193.726 202.222C204.496 184.755 211.513 165.68 213.739 148.008L222.427 142.178Z"
              fill="#F2F2F5"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="301" height="346" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case 'no-entry-sign':
      return (
        <svg
          width="212"
          height="268"
          viewBox="0 0 212 268"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.27148 194.921C9.27148 227.167 20.5912 249.859 38.8935 260.433C57.1958 271.007 82.5075 269.482 110.411 253.346C138.314 237.21 163.626 209.526 181.928 177.804C200.23 146.082 211.55 110.33 211.55 78.0643C211.55 45.7986 200.23 23.1192 181.928 12.552C163.626 1.98489 138.341 3.50311 110.411 19.6393C82.4806 35.7756 57.1958 63.4665 38.8667 95.1746C20.5375 126.883 9.27148 162.649 9.27148 194.921Z"
            fill="#DC2A2A"
          />
          <path
            d="M173.207 7.51366C154.898 -3.06021 129.613 -1.53527 101.69 14.601C73.7667 30.7372 48.4751 58.4214 30.1728 90.1363C11.8705 121.851 0.550781 157.61 0.550781 189.883C0.550781 222.155 11.8705 244.848 30.1728 255.395L38.901 260.433C20.5987 249.859 9.27894 227.187 9.27894 194.921C9.27894 162.655 20.592 126.896 38.8674 95.1746C57.1429 63.453 82.5082 35.7689 110.412 19.6393C138.315 3.50982 163.626 1.97816 181.929 12.552L173.207 7.51366Z"
            fill="#CCCCCC"
          />
          <path
            d="M24.3984 167.687L196.424 68.31V105.298L24.3984 204.675V167.687Z"
            fill="#F2F2F5"
          />
        </svg>
      );

    case 'clock':
      return <SpriteSheetAnimater source={clockSpriteSheet} />;

    default:
      return <div>{type ? type : 'missing type'}</div>;
  }
};

export default Illustration;
