import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import * as styles from './Question.module.scss';
import { PropTypes } from 'prop-types';
import { Heading, Button } from '@dnb/eufemia/components';
import { StatusContext } from '../../contexts/StatusProvider';
import Illustration from '../illustrations/Illustration';
import { motion } from 'framer-motion';

const Question = ({
  question,
  answers,
  illustrationLeft,
  buttonHolding,
  setButtonHolding,
}) => {
  const [status, dispatch] = useContext(StatusContext);
  const [answered, setAnswered] = useState(false);
  const [selected, setSelected] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const checkAnswer = () => {
    if (selected) {
      setAnswered(true);
    }
  };

  const submitAnswer = () => {
    if (isCorrect) {
      dispatch({ type: 'answer_correct' });
    } else {
      dispatch({ type: 'answer_wrong' });
    }
    dispatch({ type: 'next_question' });
  };

  useEffect(() => {
    if (selected) {
      const currentAnswer = answers.find(
        (element) => element._key === selected
      );
      if (currentAnswer.answerCorrect) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }
    }
  }, [selected]);

  const answerList = [];
  for (const answer of answers) {
    answerList.push(
      <button
        key={answer._key}
        className={classNames(
          styles.optionButton,
          { [styles.selected]: answer._key === selected },
          { [styles.answered]: answered === true },
          { [styles.correct]: answer.answerCorrect === true },
          { [styles.wrong]: answer.answerCorrect !== true }
        )}
        disabled={answered}
        onClick={() => {
          setSelected(answer._key);
        }}
      >
        <span>{answer.answerAlternative}</span>
      </button>
    );
  }

  const handleTapStart = (e) => {
    const body = document.querySelector('body');
    body.classList.add('prevent-scroll');
    e.preventDefault();
    window.oncontextmenu = function () {
      return false;
    };

    setButtonHolding(true);
  };

  const handleTapEnd = () => {
    const body = document.querySelector('body');
    body.classList.remove('prevent-scroll');
    setButtonHolding(false);
  };

  const toggleButtonHolding = (e) => {
    if (buttonHolding === true) {
      handleTapEnd(e);
    } else if (buttonHolding === false) {
      handleTapStart(e);
    }
  };

  return (
    <div
      className={classNames(styles.question, {
        [styles.constraint]: illustrationLeft,
      })}
    >
      <Heading className={styles.heading}>{question}</Heading>
      <div className={styles.buttons}>
        <motion.button
          onClick={toggleButtonHolding}
          className={styles.illustrationToggle}
        >
          <span>
            Trykk for å se {buttonHolding === false ? 'bildet' : 'spørsmålet'}
          </span>
        </motion.button>
      </div>
      <div
        className={classNames(styles.contentOuter, {
          [styles.hide]: buttonHolding,
        })}
      >
        <div className={styles.answerList}>{answerList}</div>
        <div className={styles.buttons}>
          {answered ? (
            <Button onClick={submitAnswer}>Neste</Button>
          ) : (
            <Button onClick={checkAnswer} disabled={!selected}>
              Sjekk svar
            </Button>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.illustrationLeftBox, {
          [styles.show]: buttonHolding,
        })}
      >
        <Illustration type={illustrationLeft} />
      </div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
};

Question.defaultProps = {
  question: null,
  answers: null,
};

export default Question;
