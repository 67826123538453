// extracted by mini-css-extract-plugin
export var corner = "AnimationSwitch-module--corner--3Us0T";
export var signPost = "AnimationSwitch-module--signPost--1_xy3";
export var roadSignIllustration = "AnimationSwitch-module--roadSignIllustration--3Xozn";
export var roundaboutOuter = "AnimationSwitch-module--roundaboutOuter--2MjmL";
export var roundPopUp = "AnimationSwitch-module--roundPopUp--1m1A3";
export var spawn = "AnimationSwitch-module--spawn--1QNmA";
export var intersectionOuter = "AnimationSwitch-module--intersectionOuter--Vgv8m";
export var intersection = "AnimationSwitch-module--intersection--2-X6m";
export var letters = "AnimationSwitch-module--letters--4yhmz";
export var fade = "AnimationSwitch-module--fade--2ok6T";
export var bussOuter = "AnimationSwitch-module--bussOuter--_ufLp";
export var abortPassingOuter = "AnimationSwitch-module--abortPassingOuter--sveoT";
export var passingOuter = "AnimationSwitch-module--passingOuter--2j3E1";
export var popUp = "AnimationSwitch-module--popUp--1ckIX";