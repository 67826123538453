import React from 'react';
import * as styles from './SpriteSheetAnimator.module.scss';

const SpriteSheetAnimator = ({ source }) => {
  const style = {
    backgroundImage: `url(${source})`,
    width: '434px',
    height: '434px',
  };

  return (
    <div className={styles.spriteContainer}>
      {source && <div style={style} className={styles.spriteImage}></div>}
    </div>
  );
};

export default SpriteSheetAnimator;
